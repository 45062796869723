#homeVideo {
  width: auto;
  height: 100%;
  position: fixed;
  z-index: -1; 
}

#homeText {
  font-family: 'Cabin', sans-serif;
  padding: 37vh 0 0 3vw;
  z-index: 1;
  font-weight: 700;
  color: #660033;
  font-size: 3.5vw;
}

.mainTitleLine2 {
  padding-left: 2vw;
}

.mainTitleName {
  color: rgb(255, 106, 6);
  background-color: #17e9e0;
  padding: 0 0.5vw 0 0.5vw;
}

.mainIcons {
  padding-left: 5vw;
}

.mainIcons a {
  padding-right: 3vw;
  color: #17e9e0 !important;
  font-size: 3.1vw;
}

@media only screen and (min-width: 1000px) {
  .mainIcon:hover, .mainIcon:active {
    font-size: 3.5vw;
  }
}



@media only screen and (max-width: 450px) {
  #homeVideo {
    display: none;
  }

  #homeText {
    color: #e3e2df;
    padding: 30vh 0 0 2vw;
    font-size: 6vw;
  }

  .mainTitleName {
    font-size: 8vw;
  }

  .mainIcons {
    padding: 0;
    margin-top: 30vh;
    text-align: center;
  }

  .mainIcons a {
    font-size: 10vw;
    padding: 0 5vw 0 5vw;
  }
}