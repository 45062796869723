@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans+SC|Armata|Cabin|Changa|Gloria+Hallelujah|Kanit|Karla|Monda&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bai+Jamjuree|Be+Vietnam|Bungee+Inline|Bungee+Shade|Finger+Paint|Sulphur+Point&display=swap);
@import url(https://fonts.googleapis.com/css?family=Cabin:400,500,600,700&display=swap);

/* font-family: 'Karla', sans-serif; */
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Monda', sans-serif; */
  /* font-family: 'Changa', sans-serif; */
  /* font-family: 'Gloria Hallelujah', cursive; */
  /* font-family: 'Armata', sans-serif; */
  /* font-family: 'Finger Paint', cursive; */
  /* font-family: 'Sulphur Point', sans-serif; */
  /* font-family: 'Bungee Inline', cursive; */
  /* font-family: 'Bai Jamjuree', sans-serif; */
  /* font-family: 'Be Vietnam', sans-serif; */
  /* font-family: 'Bungee Shade', cursive; */

/* Colors
  wine: #660033
  orange: #FF8B06
          rgb(255, 106, 6)
  mint: #17e9e0
  beige: #e3e2df
  baby pink: #e3afbc
  pink: #ee4c7c
  dark-pink: #9a1750
 */


body {
  font-family: 'Changa', sans-serif;
  background-color: #660033;
}


/* NAVBAR */
#navbar {
  height: 6vh;
  width: 100vw;
  background-color: #660033 !important;
  font-weight: 500;
  font-size: 1.5vw;
}

#homeTitle {
  font-family: 'Bungee Shade', cursive;
  color: rgb(255, 106, 6);
  font-size: 1.8vw;
}

.navOption {
  color: #17e9e0 !important;
  font-family: 'Changa', sans-serif;
}



@media only screen and (max-width: 450px) {
  #navbar {
    height: 10vh;
    width: 100vw;
    font-size: 6vw;
  }

  #homeTitle {
    font-size: 6.5vw;
  }
  
  .navRight {
    width: 60vw;
    text-align: center;
    display: grid;
    grid-template-columns: 20vw 20vw 20vw;
  }
}
#homeVideo {
  width: auto;
  height: 100%;
  position: fixed;
  z-index: -1; 
}

#homeText {
  font-family: 'Cabin', sans-serif;
  padding: 37vh 0 0 3vw;
  z-index: 1;
  font-weight: 700;
  color: #660033;
  font-size: 3.5vw;
}

.mainTitleLine2 {
  padding-left: 2vw;
}

.mainTitleName {
  color: rgb(255, 106, 6);
  background-color: #17e9e0;
  padding: 0 0.5vw 0 0.5vw;
}

.mainIcons {
  padding-left: 5vw;
}

.mainIcons a {
  padding-right: 3vw;
  color: #17e9e0 !important;
  font-size: 3.1vw;
}

@media only screen and (min-width: 1000px) {
  .mainIcon:hover, .mainIcon:active {
    font-size: 3.5vw;
  }
}



@media only screen and (max-width: 450px) {
  #homeVideo {
    display: none;
  }

  #homeText {
    color: #e3e2df;
    padding: 30vh 0 0 2vw;
    font-size: 6vw;
  }

  .mainTitleName {
    font-size: 8vw;
  }

  .mainIcons {
    padding: 0;
    margin-top: 30vh;
    text-align: center;
  }

  .mainIcons a {
    font-size: 10vw;
    padding: 0 5vw 0 5vw;
  }
}
#aboutme {
  color: #f38ba5;
  width: 100vw;
  height: 94vh;
  z-index: -1; 

  display: grid;
  grid-template-columns: 85vw 15vw;
  grid-template-areas:
    "bio icons";
}

.bio {
  grid-area: bio;
  padding: 10vh 0 0 7vw;
}

.bioName {
  font-size: 2.5vw;
  font-weight: 700;
  margin-bottom: 4vh;
}

.bioDetails {
  display: grid;
  grid-template-columns: 20vw 30vw 22vw;
  grid-column-gap: 2vw;
}

.bioDetail {
  font-size: 1.7vw;
  font-weight: 600;
  line-height: 1.3;
}

.skillIcon {
  color:#f38ba5;
}

.bioDetailTitle {
  font-size: 2vw;
  margin-bottom: 1vh;
}

.color1 {
  color: #e3e2df;
}
.color2 {
  color: #17e9e0;
}


.aboutmeIcons {
  grid-area: icons;
  display: grid;
  grid-template-rows: 13vh 10vh 10vh 50vh;
  padding: 20vh 0 0 2vw;
  justify-self: start;
  text-align: center;
}

.aboutmeIcons a {
  color: #e3e2df;
  font-size: 3.1vw;
}

@media only screen and (min-width: 1000px) {
  .aboutmeIcon:hover, .aboutmeIcon:active {
    font-size: 3.5vw;
  }
}

.resume {
  font-size: 1vw;
  margin: 0;
}



@media only screen and (max-width: 450px) {
  #aboutme {
    height: 90vh;
    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: 77vh 13vh;
    grid-template-areas:
      "bio"
      "icons";
  }

  .bio {
    padding: 0 5vw 0 5vw;
  }

  .bioDetails {
    display: block;
  }

  .bioName {
    font-size: 2.8vh;
    margin-bottom: 2vh;
  }

  .bioDetailTitle {
    font-size: 2.5vh;
    margin: 1.2vh 0 0 0;
  }

  .bioDetail {
    font-size: 2.2vh;
  }

  .aboutmeIcons {
    grid-area: icons;
    display: grid;
    grid-template-columns: 17vw 17vw 17vw 17vw;
    padding: 0 16vw 0 16vw;
  }

  .aboutmeIcons a {
    font-size: 5vh;
  }

  .resume {
    font-size: 2vh;
  }
}
#project {
  width: 100vw;
  height: auto;
  color:#e3afbc;
}

#projectCon {
  padding: 5vh 3vw 5vh 3vw;
  display: grid;
  grid-template-columns: 46vw 46vw;
  grid-column-gap: 2vw;
  grid-template-rows: 60vh 60vh;
  grid-row-gap: 2vh;
}

.preview {
  text-align: center;
  width: 46vw;
  height: auto;
  border-radius: 2%;
}

.previewImg {
  width: 44vw;
  height: auto;
  padding: 1vw;
  /* background-color: #e3afbc; */
  /* background-image: linear-gradient(to bottom right, #9a1750, #e3e2df); */
  background-image: -webkit-gradient(linear, left top, left bottom, from(#660033), to(#e3e2df));
  background-image: linear-gradient(#660033, #e3e2df);
  border-radius: 3%;
}

@media only screen and (min-width: 1000px) {
  .previewImg:hover, .previewImg:active {
    width: 45vw;
  }
}

.previewTitle {
  font-family: 'Bungee Shade', cursive;
  font-size: 1.8vw;
  font-weight: 600;
  margin: 0 0 0.7vh 0;
}



@media only screen and (max-width: 450px) {
  #projectCon {
    padding: 2vh 3vw 2vh 3vw;
    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: auto;
    grid-row-gap: 3vh;
  }

  .preview {
    width: 94vw;
  }

  .previewImg {
    width: 80vw;
  }

  .previewTitle {
    font-size: 2.5vh;
  }
}
.projectShow {
  width: 100vw;
  height: auto;
  padding: 5vh 8vw 5vh 8vw;
  color: #e3e2df;
}

.projectTitle {
  font-family: 'Bungee Shade', cursive;
  font-size: 2.2vw;
  font-weight: 600;
  margin-left: 7vw;
  color: #17e9e0;
}

.projectDetail {
  width: 70vw;
  font-size: 1.5vw;
  font-weight: 400;
  margin: 1vh 0 2vh 7vw;
  line-height: 1;
}

.projectCarousel {
  width: 70vw;
  margin-left: 7vw;
}

.frontbackDetail {
  width: 70vw;
  font-size: 1.5vw;
  font-weight: 400;
  text-align: start;
  display: grid;
  grid-template-columns: 33vw 33vw;
  grid-column-gap: 4vw;
  margin: 3vh 0 5vh 7vw;
  line-height: 1.2;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.endTitle {
  color: rgb(255, 106, 6);
  background-color: #17e9e0;
  width: 10.5vw;
  font-weight: 600;
  font-size: 1.7vw;
  padding: 0.5vh 0 0.5vh 1vw;
  margin-bottom: 0.5vh;
}

.showIcon {
  color: #660033;
  display: inline;
  margin-left: 0.5vw;
}

@media only screen and (min-width: 1000px) {
  .showIcon:hover, .showIcon:active {
    font-size: 2vw;
  }
}

.arrowIcon {
  color: rgb(255, 106, 6);
}

.demo {
  width: 70vw;
  margin-left: 7vw;
}

.demoTitle {
  color: rgb(255, 106, 6);
  background-color: #17e9e0;
  width: 11vw;
  font-weight: 600;
  font-size: 1.7vw;
  padding-left: 1vw;
  margin-bottom: 1vh;
}

.demoVideo {
  width: 50vw;
  height: auto;
}



@media only screen and (max-width: 450px) {
  .projectShow {
    padding: 2vh 3vw 2vh 3vw;
  }

  .projectTitle {
    font-size: 3vh;
    margin-left: 2vw;
  }
  
  .projectDetail {
    width: 90vw;
    font-size: 2.5vh;
    margin-left: 2vw;
  }

  .projectCarousel {
    width: 90vw;
    margin-left: 2vw;
  }

  .frontbackDetail {
    width: 90vw;
    font-size: 2.5vh;
    display: block;
    margin: 3vh 0 3vh 2vw;
  }

  .endTitle {
    width: 30vw;
    font-size: 2.7vh;
    padding: 0.5vh 0 0.5vh 1vw;
    margin-bottom: 0.5vh;
  }
  
  .showIcon {
    color: #660033;
    display: inline;
    margin-left: 0.5vw;
  }

  .demo {
    width: 90vw;
    margin-left: 2vw;
  }

  .demoTitle {
    width: 30vw;
    font-weight: 600;
    font-size: 2.7vh;
    padding-left: 1vw;
    margin-bottom: 1vh;
  }
  
  .demoVideo {
    width: 90vw;
    height: auto;
  }
}
#blog {
  padding: 5vh 5vw 5vh 5vw;
  display: grid;
  grid-template-columns: 28vw 28vw 28vw;
  grid-column-gap: 3vw;
  grid-row-gap: 4vh;
}

.blogImg {
  width: 28vw;
  height: 30vh;
  background-color: white;
  border-radius: 3%;
  box-shadow: 5px 5px rgba(255, 106, 6, 0.767);
}

.blogText {
  color: #e3e2df;
  font-size: 1.5vw;
  font-weight: 500;
  margin: 1.5vh 0 0 0.5vw;
  display: grid;
  grid-template-columns: 3vw 25vw;
}

.blogIcon {
  color: #17e9e0;
  font-size: 2.2vw;
}



@media only screen and (max-width: 450px) {
  #blog {
    padding: 2vh 3vw 2vh 3vw;
    display: block;
  }
  
  .blogImg {
    width: 80vw;
    height: auto;
    margin-left: 7vw;
  }
  
  .blogText {
    font-size: 2.5vh;
    margin: 2vh 0 3vh 9vw;
    display: grid;
    grid-template-columns: 8vw 70vw;
  }
  
  .blogIcon {
    font-size: 4vh;
  }
}
