#project {
  width: 100vw;
  height: auto;
  color:#e3afbc;
}

#projectCon {
  padding: 5vh 3vw 5vh 3vw;
  display: grid;
  grid-template-columns: 46vw 46vw;
  grid-column-gap: 2vw;
  grid-template-rows: 60vh 60vh;
  grid-row-gap: 2vh;
}

.preview {
  text-align: center;
  width: 46vw;
  height: auto;
  border-radius: 2%;
}

.previewImg {
  width: 44vw;
  height: auto;
  padding: 1vw;
  /* background-color: #e3afbc; */
  /* background-image: linear-gradient(to bottom right, #9a1750, #e3e2df); */
  background-image: linear-gradient(#660033, #e3e2df);
  border-radius: 3%;
}

@media only screen and (min-width: 1000px) {
  .previewImg:hover, .previewImg:active {
    width: 45vw;
  }
}

.previewTitle {
  font-family: 'Bungee Shade', cursive;
  font-size: 1.8vw;
  font-weight: 600;
  margin: 0 0 0.7vh 0;
}



@media only screen and (max-width: 450px) {
  #projectCon {
    padding: 2vh 3vw 2vh 3vw;
    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: auto;
    grid-row-gap: 3vh;
  }

  .preview {
    width: 94vw;
  }

  .previewImg {
    width: 80vw;
  }

  .previewTitle {
    font-size: 2.5vh;
  }
}