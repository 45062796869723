#blog {
  padding: 5vh 5vw 5vh 5vw;
  display: grid;
  grid-template-columns: 28vw 28vw 28vw;
  grid-column-gap: 3vw;
  grid-row-gap: 4vh;
}

.blogImg {
  width: 28vw;
  height: 30vh;
  background-color: white;
  border-radius: 3%;
  box-shadow: 5px 5px rgba(255, 106, 6, 0.767);
}

.blogText {
  color: #e3e2df;
  font-size: 1.5vw;
  font-weight: 500;
  margin: 1.5vh 0 0 0.5vw;
  display: grid;
  grid-template-columns: 3vw 25vw;
}

.blogIcon {
  color: #17e9e0;
  font-size: 2.2vw;
}



@media only screen and (max-width: 450px) {
  #blog {
    padding: 2vh 3vw 2vh 3vw;
    display: block;
  }
  
  .blogImg {
    width: 80vw;
    height: auto;
    margin-left: 7vw;
  }
  
  .blogText {
    font-size: 2.5vh;
    margin: 2vh 0 3vh 9vw;
    display: grid;
    grid-template-columns: 8vw 70vw;
  }
  
  .blogIcon {
    font-size: 4vh;
  }
}