@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans+SC|Armata|Cabin|Changa|Gloria+Hallelujah|Kanit|Karla|Monda&display=swap');
@import url('https://fonts.googleapis.com/css?family=Bai+Jamjuree|Be+Vietnam|Bungee+Inline|Bungee+Shade|Finger+Paint|Sulphur+Point&display=swap'); 
@import url('https://fonts.googleapis.com/css?family=Cabin:400,500,600,700&display=swap');
  /* font-family: 'Karla', sans-serif; */
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Monda', sans-serif; */
  /* font-family: 'Changa', sans-serif; */
  /* font-family: 'Gloria Hallelujah', cursive; */
  /* font-family: 'Armata', sans-serif; */
  /* font-family: 'Finger Paint', cursive; */
  /* font-family: 'Sulphur Point', sans-serif; */
  /* font-family: 'Bungee Inline', cursive; */
  /* font-family: 'Bai Jamjuree', sans-serif; */
  /* font-family: 'Be Vietnam', sans-serif; */
  /* font-family: 'Bungee Shade', cursive; */

/* Colors
  wine: #660033
  orange: #FF8B06
          rgb(255, 106, 6)
  mint: #17e9e0
  beige: #e3e2df
  baby pink: #e3afbc
  pink: #ee4c7c
  dark-pink: #9a1750
 */


body {
  font-family: 'Changa', sans-serif;
  background-color: #660033;
}


/* NAVBAR */
#navbar {
  height: 6vh;
  width: 100vw;
  background-color: #660033 !important;
  font-weight: 500;
  font-size: 1.5vw;
}

#homeTitle {
  font-family: 'Bungee Shade', cursive;
  color: rgb(255, 106, 6);
  font-size: 1.8vw;
}

.navOption {
  color: #17e9e0 !important;
  font-family: 'Changa', sans-serif;
}



@media only screen and (max-width: 450px) {
  #navbar {
    height: 10vh;
    width: 100vw;
    font-size: 6vw;
  }

  #homeTitle {
    font-size: 6.5vw;
  }
  
  .navRight {
    width: 60vw;
    text-align: center;
    display: grid;
    grid-template-columns: 20vw 20vw 20vw;
  }
}