.projectShow {
  width: 100vw;
  height: auto;
  padding: 5vh 8vw 5vh 8vw;
  color: #e3e2df;
}

.projectTitle {
  font-family: 'Bungee Shade', cursive;
  font-size: 2.2vw;
  font-weight: 600;
  margin-left: 7vw;
  color: #17e9e0;
}

.projectDetail {
  width: 70vw;
  font-size: 1.5vw;
  font-weight: 400;
  margin: 1vh 0 2vh 7vw;
  line-height: 1;
}

.projectCarousel {
  width: 70vw;
  margin-left: 7vw;
}

.frontbackDetail {
  width: 70vw;
  font-size: 1.5vw;
  font-weight: 400;
  text-align: start;
  display: grid;
  grid-template-columns: 33vw 33vw;
  grid-column-gap: 4vw;
  margin: 3vh 0 5vh 7vw;
  line-height: 1.2;
  animation-delay: 0.5s;
}

.endTitle {
  color: rgb(255, 106, 6);
  background-color: #17e9e0;
  width: 10.5vw;
  font-weight: 600;
  font-size: 1.7vw;
  padding: 0.5vh 0 0.5vh 1vw;
  margin-bottom: 0.5vh;
}

.showIcon {
  color: #660033;
  display: inline;
  margin-left: 0.5vw;
}

@media only screen and (min-width: 1000px) {
  .showIcon:hover, .showIcon:active {
    font-size: 2vw;
  }
}

.arrowIcon {
  color: rgb(255, 106, 6);
}

.demo {
  width: 70vw;
  margin-left: 7vw;
}

.demoTitle {
  color: rgb(255, 106, 6);
  background-color: #17e9e0;
  width: 11vw;
  font-weight: 600;
  font-size: 1.7vw;
  padding-left: 1vw;
  margin-bottom: 1vh;
}

.demoVideo {
  width: 50vw;
  height: auto;
}



@media only screen and (max-width: 450px) {
  .projectShow {
    padding: 2vh 3vw 2vh 3vw;
  }

  .projectTitle {
    font-size: 3vh;
    margin-left: 2vw;
  }
  
  .projectDetail {
    width: 90vw;
    font-size: 2.5vh;
    margin-left: 2vw;
  }

  .projectCarousel {
    width: 90vw;
    margin-left: 2vw;
  }

  .frontbackDetail {
    width: 90vw;
    font-size: 2.5vh;
    display: block;
    margin: 3vh 0 3vh 2vw;
  }

  .endTitle {
    width: 30vw;
    font-size: 2.7vh;
    padding: 0.5vh 0 0.5vh 1vw;
    margin-bottom: 0.5vh;
  }
  
  .showIcon {
    color: #660033;
    display: inline;
    margin-left: 0.5vw;
  }

  .demo {
    width: 90vw;
    margin-left: 2vw;
  }

  .demoTitle {
    width: 30vw;
    font-weight: 600;
    font-size: 2.7vh;
    padding-left: 1vw;
    margin-bottom: 1vh;
  }
  
  .demoVideo {
    width: 90vw;
    height: auto;
  }
}