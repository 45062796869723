#aboutme {
  color: #f38ba5;
  width: 100vw;
  height: 94vh;
  z-index: -1; 

  display: grid;
  grid-template-columns: 85vw 15vw;
  grid-template-areas:
    "bio icons";
}

.bio {
  grid-area: bio;
  padding: 10vh 0 0 7vw;
}

.bioName {
  font-size: 2.5vw;
  font-weight: 700;
  margin-bottom: 4vh;
}

.bioDetails {
  display: grid;
  grid-template-columns: 20vw 30vw 22vw;
  grid-column-gap: 2vw;
}

.bioDetail {
  font-size: 1.7vw;
  font-weight: 600;
  line-height: 1.3;
}

.skillIcon {
  color:#f38ba5;
}

.bioDetailTitle {
  font-size: 2vw;
  margin-bottom: 1vh;
}

.color1 {
  color: #e3e2df;
}
.color2 {
  color: #17e9e0;
}


.aboutmeIcons {
  grid-area: icons;
  display: grid;
  grid-template-rows: 13vh 10vh 10vh 50vh;
  padding: 20vh 0 0 2vw;
  justify-self: start;
  text-align: center;
}

.aboutmeIcons a {
  color: #e3e2df;
  font-size: 3.1vw;
}

@media only screen and (min-width: 1000px) {
  .aboutmeIcon:hover, .aboutmeIcon:active {
    font-size: 3.5vw;
  }
}

.resume {
  font-size: 1vw;
  margin: 0;
}



@media only screen and (max-width: 450px) {
  #aboutme {
    height: 90vh;
    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: 77vh 13vh;
    grid-template-areas:
      "bio"
      "icons";
  }

  .bio {
    padding: 0 5vw 0 5vw;
  }

  .bioDetails {
    display: block;
  }

  .bioName {
    font-size: 2.8vh;
    margin-bottom: 2vh;
  }

  .bioDetailTitle {
    font-size: 2.5vh;
    margin: 1.2vh 0 0 0;
  }

  .bioDetail {
    font-size: 2.2vh;
  }

  .aboutmeIcons {
    grid-area: icons;
    display: grid;
    grid-template-columns: 17vw 17vw 17vw 17vw;
    padding: 0 16vw 0 16vw;
  }

  .aboutmeIcons a {
    font-size: 5vh;
  }

  .resume {
    font-size: 2vh;
  }
}